import { combineReducers } from '@reduxjs/toolkit';
import fuse from './fuse';
import i18n from './i18nSlice';
import user from './userSlice';
import profile from '../main/profile/store/profileSlice'
import players from '../main/players/store/playerSlice'



const createReducer = (asyncReducers) => (state, action) => {
   const combinedReducer = combineReducers({
      fuse,
      i18n,
      user,
      profile,
      players,
      ...asyncReducers,
   });

   /*
	Reset the redux store when user logged out
	 */
   if (action.type === 'user/userLoggedOut') {
      // state = undefined;
   }

   return combinedReducer(state, action);
};

export default createReducer;
