import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { baseUrl } from 'app/configs/apiConfig';

export const getAllPlayers = createAsyncThunk(
   'players/getAllPlayers',
   async (_, { dispatch, getState }) => {
      try {
         const response = await axios.get(`${baseUrl}/user`);
         const userData = await response.data.data.data;
         return userData;
      } catch (error) {
         console.error('Error fetching users', error);
         throw error;
      }
   }
);


const playerSlice = createSlice({
   name: 'players',
   initialState: {
      data: [],
      status: 'idle',
      error: null,
   },
   reducers: {},
   extraReducers: (builder) => {
      builder
         .addCase(getAllPlayers.pending, (state) => {
            state.status = 'loading';
         })
         .addCase(getAllPlayers.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.data = action.payload;
         })
         .addCase(getAllPlayers.rejected, (state, action) => {
            state.status = 'failed';
            state.error = action.error.message;
         });
   },
});

export default playerSlice.reducer;