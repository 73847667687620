import { lazy } from "react";

const Players = lazy(() => import("./Players"));

const PlayersConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: "/apps/players",
      element: <Players />,
    },
  ],
};

export default PlayersConfig;
