import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, NavLink } from 'react-router-dom';
import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import { selectUser } from 'app/store/userSlice';
import { useLocation } from 'react-router-dom';

function UserMenu(props) {
   const user = useSelector(selectUser);

   const [userMenu, setUserMenu] = useState(null);

   const userMenuClick = (event) => {
      setUserMenu(event.currentTarget);
   };

   const userMenuClose = () => {
      setUserMenu(null);
   };

   const location = useLocation();
   const showOnRoutes = ['/onboarding/step-one','/onboarding/step-two','/onboarding/step-three','/onboarding/step-four','/onboarding/step-five','/onboarding/step-six', '/onboarding/permissions'];

   const shouldShowDiv = showOnRoutes.includes(location.pathname);

   return (
      <>
         <div>
            
         </div>
         {!shouldShowDiv && (<Button
            className="min-h-40 min-w-40 px-0 md:px-16 py-0 md:py-6"
            onClick={userMenuClick}
            color="inherit">
            <div className="hidden md:flex flex-col mx-4 items-end">
               <Typography component="span" className="font-semibold flex">
                  {user.data.displayName}
               </Typography>
               <Typography
                  className="text-11 font-medium capitalize"
                  color="text.secondary">
                  {user.role.toString()}
                  {(!user.role ||
                     (Array.isArray(user.role) && user.role.length === 0)) &&
                     'Guest'}
               </Typography>
               <Typography>{localStorage.getItem('email')}</Typography>
            </div>

            {/* {user.data.photoURL ? (
          <Avatar className="md:mx-4" alt="user photo" src={user.data.photoURL} />
        ) : (
          <Avatar className="md:mx-4">{user.data.displayName[0]}</Avatar>
        )} */}
         </Button>)}

          {shouldShowDiv && (<Button
            className="min-h-40 min-w-40 px-0 md:px-16 py-0 md:py-6"
            onClick={userMenuClick}
            color="inherit">
            <div className="hidden md:flex flex-col mx-4 items-end">
               <Typography className="font-semibold flex">{localStorage.getItem('email')}</Typography>
            </div>
         </Button>)}


         <Popover
            open={Boolean(userMenu)}
            anchorEl={userMenu}
            onClose={userMenuClose}
            anchorOrigin={{
               vertical: 'bottom',
               horizontal: 'center',
            }}
            transformOrigin={{
               vertical: 'top',
               horizontal: 'center',
            }}
            classes={{
               paper: 'py-8',
            }}>
            
            {!user.role || user.role.length === 0 ? (
               <>
                  <MenuItem component={Link} to="/sign-in" role="button">
                     <ListItemIcon className="min-w-40">
                        <FuseSvgIcon>heroicons-outline:lock-closed</FuseSvgIcon>
                     </ListItemIcon>
                     <ListItemText primary="Sign In" />
                  </MenuItem>
                  <MenuItem component={Link} to="/sign-up" role="button">
                     <ListItemIcon className="min-w-40">
                        <FuseSvgIcon>heroicons-outline:user-add </FuseSvgIcon>
                     </ListItemIcon>
                     <ListItemText primary="Sign up" />
                  </MenuItem>
               </>
            ) : (
               <>
                  <MenuItem
                     component={Link}
                     to="/apps/profile"
                     onClick={userMenuClose}
                     role="button">
                     <ListItemIcon className="min-w-40">
                        <FuseSvgIcon>heroicons-outline:user-circle</FuseSvgIcon>
                     </ListItemIcon>
                     <ListItemText primary="My Profile" />
                  </MenuItem>
                  <MenuItem
                     component={Link}
                     to="/apps/mailbox"
                     onClick={userMenuClose}
                     role="button">
                     <ListItemIcon className="min-w-40">
                        <FuseSvgIcon>heroicons-outline:mail-open</FuseSvgIcon>
                     </ListItemIcon>
                     <ListItemText primary="Inbox" />
                  </MenuItem>
                  <MenuItem
                     component={NavLink}
                     to="/sign-out"
                     onClick={() => {
                        userMenuClose();
                     }}>
                     <ListItemIcon className="min-w-40">
                        <FuseSvgIcon>heroicons-outline:logout</FuseSvgIcon>
                     </ListItemIcon>
                     <ListItemText primary="Sign out" />
                  </MenuItem>
               </>
            )}
         </Popover>
      </>
   );
}

export default UserMenu;
