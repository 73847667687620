import { lazy } from 'react';

const HomeApp = lazy(() => import('./HomeApp'));

const homeAppConfig = {
   settings: {
      layout: {
         config: {},
      },
   },
   routes: [
      {
         path: 'apps/profile',
         element: <HomeApp />,
      },
   ],
};

export default homeAppConfig;
