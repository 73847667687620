import { useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';
import * as yup from 'yup';
import _ from '@lodash';
import AvatarGroup from '@mui/material/AvatarGroup';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import FormHelperText from '@mui/material/FormHelperText';
import { IconButton } from '@mui/material';
import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import jwtService from '../../auth/services/jwtService';
import { border } from '@mui/system';

/**
 * Form Validation Schema
 */
const schema = yup.object().shape({
   // displayName: yup.string().required('You must enter display name'),
   email: yup
      .string()
      .email('You must enter a valid email')
      .required('You must enter a email'),
   password: yup
      .string()
      .required('Please enter your password.')
      .min(8, 'Password is too short - should be 8 chars minimum.'),
   passwordConfirm: yup
      .string()
      .oneOf([yup.ref('password'), null], 'Passwords must match'),
   // acceptTermsConditions: yup.boolean().oneOf([true], 'The terms and conditions must be accepted.'),
});

const defaultValues = {
   // displayName: '',
   email: '',
   password: '',
   passwordConfirm: '',
   // acceptTermsConditions: true,
};

function SignUpPage() {
   // State to track whether to show the password
   const [showPassword, setShowPassword] = useState(false);
   const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);

   const { control, formState, handleSubmit, reset } = useForm({
      mode: 'onChange',
      defaultValues,
      resolver: yupResolver(schema),
   });

   const { isValid, dirtyFields, errors, setError } = formState;

   function onSubmit({ password, email }) {
      jwtService
         .createUser({
            password,
            email,
         })
         .then((user) => {
            // No need to do anything, registered user data will be set at app/auth/AuthContext
         })
         // .catch((_errors) => {
         //    _errors.forEach((error) => {
         //       setError(error.type, {
         //          type: 'manual',
         //          message: error.message,
         //       });
         //    });
         // });
   }

   return (
      <div className="flex flex-col sm:flex-row items-center md:items-start sm:justify-center md:justify-start flex-1 min-w-0">
         <Paper className="h-full sm:h-auto md:flex md:items-center md:justify-end w-full sm:w-auto md:h-full md:w-1/2 py-8 px-16 sm:p-48 md:p-64 sm:rounded-2xl md:rounded-none sm:shadow md:shadow-none ltr:border-r-1 rtl:border-l-1">
            <div className="w-full max-w-320 sm:w-320 mx-auto sm:mx-0">
               <img
                  className="w-96"
                  src="assets/images/player/awtsyde-logo.png"
                  alt="logo"
               />

               <Typography className="mt-32 text-4xl font-extrabold tracking-tight leading-tight">
                  Sign up
               </Typography>
               <div className="flex items-baseline mt-2 font-medium">
                  <Typography>Already have an account?</Typography>
                  <Link className="ml-4" to="/sign-in">
                     Sign in
                  </Link>
               </div>

               <form
                  name="registerForm"
                  noValidate
                  className="flex flex-col justify-center w-full mt-32"
                  onSubmit={handleSubmit(onSubmit)}>
                  <Controller
                     name="email"
                     control={control}
                     render={({ field }) => (
                        <TextField
                           {...field}
                           className="mb-24"
                           label="Email"
                           type="email"
                           error={!!errors.email}
                           helperText={errors?.email?.message}
                           variant="outlined"
                           required
                           fullWidth
                        />
                     )}
                  />

                  <Controller
                     name="password"
                     control={control}
                     render={({ field }) => (
                        <TextField
                           {...field}
                           className="mb-24"
                           label="Password"
                           type={showPassword ? 'text' : 'password'} // Toggle password visibility
                           error={!!errors.password}
                           helperText={errors?.password?.message}
                           variant="outlined"
                           required
                           fullWidth
                           InputProps={{
                              endAdornment: showPassword ? (
                                 <IconButton
                                    aria-label="eye-off"
                                    onClick={() =>
                                       setShowPassword(!showPassword)
                                    }>
                                    <FuseSvgIcon
                                       className="text-48"
                                       size={24}
                                       color="action">
                                       heroicons-outline:eye-off
                                    </FuseSvgIcon>
                                 </IconButton>
                              ) : (
                                 <IconButton
                                    aria-label="eye"
                                    onClick={() =>
                                       setShowPassword(!showPassword)
                                    }>
                                    <FuseSvgIcon
                                       className="text-48"
                                       size={24}
                                       color="action">
                                       heroicons-outline:eye
                                    </FuseSvgIcon>
                                 </IconButton>
                              ),
                           }}
                        />
                     )}
                  />

                  <Controller
                     name="passwordConfirm"
                     control={control}
                     render={({ field }) => (
                        <TextField
                           {...field}
                           className="mb-24"
                           label="Password (Confirm)"
                           type={showPasswordConfirm ? 'text' : 'password'} // Toggle password visibility
                           error={!!errors.passwordConfirm}
                           helperText={errors?.passwordConfirm?.message}
                           variant="outlined"
                           required
                           fullWidth
                           InputProps={{
                              endAdornment: showPasswordConfirm ? (
                                 <IconButton
                                    aria-label="eye-off"
                                    onClick={() =>
                                       setShowPasswordConfirm(
                                          !showPasswordConfirm
                                       )
                                    }>
                                    <FuseSvgIcon
                                       className="text-48"
                                       size={24}
                                       color="action">
                                       heroicons-outline:eye-off
                                    </FuseSvgIcon>
                                 </IconButton>
                              ) : (
                                 <IconButton
                                    aria-label="eye"
                                    onClick={() =>
                                       setShowPasswordConfirm(
                                          !showPasswordConfirm
                                       )
                                    }>
                                    <FuseSvgIcon
                                       className="text-48"
                                       size={24}
                                       color="action">
                                       heroicons-outline:eye
                                    </FuseSvgIcon>
                                 </IconButton>
                              ),
                           }}
                        />
                     )}
                  />
                  <Button
                     variant="contained"
                     className="w-full mt-24 bg-awtsyde_green "
                     aria-label="Register"
                     disabled={_.isEmpty(dirtyFields) || !isValid}
                     type="submit"
                     size="large">
                     Create your free account
                  </Button>

                  <div className="flex items-center mt-32">
                     <div className="flex-auto mt-px border-t" />
                     <Typography className="mx-8" color="text.secondary">
                        Or continue with
                     </Typography>
                     <div className="flex-auto mt-px border-t" />
                  </div>

                  <div className="flex items-center mt-32 space-x-16 mb-32">
                     <Button variant="outlined" className="flex-auto">
                        <img
                           src="/assets/images/logo/google_icon.png"
                           alt="google-icon"
                           style={{
                              width: '24px',
                              height: '24px',
                              objectFit: 'cover',
                           }}
                        />
                     </Button>

                     <Button variant="outlined" className="flex-auto">
                        <div className="w-full flex justify-center">
                           <Link to="/sign-up-with-phone">
                              <FuseSvgIcon size={20} color="action">
                                 feather:phone
                              </FuseSvgIcon>
                           </Link>
                        </div>
                     </Button>

                     <Button variant="outlined" className="flex-auto">
                        <FuseSvgIcon size={20} color="action">
                           feather:user
                        </FuseSvgIcon>
                     </Button>
                  </div>

                  <div className="flex items-baseline mt-2 font-medium">
                     <Typography>
                        By signing up you have agreed to our{' '}
                        <Link to="#">Terms of Use</Link> and have read the{' '}
                        <Link to="#">Privacy Policy </Link> of Awtsyde
                     </Typography>
                  </div>
                  <div className="flex items-baseline mt-2 font-medium">
                     <Typography>
                        Already have an account ?
                        <Link to="/sign-in">Log In </Link>
                     </Typography>
                  </div>
               </form>
            </div>
         </Paper>

         <Box
            className="relative hidden md:flex flex-auto items-center justify-center h-full p-64 lg:px-112 overflow-hidden"
            sx={{
               backgroundImage: `linear-gradient(to top,#033249, transparent), url('assets/images/player/auth-bg.png')`,
               backgroundSize: 'cover',
            }}>
            <svg
               className="absolute inset-0 pointer-events-none"
               viewBox="0 0 960 540"
               width="100%"
               height="100%"
               preserveAspectRatio="xMidYMax slice"
               xmlns="http://www.w3.org/2000/svg">
               <Box
                  component="g"
                  sx={{ color: 'primary.light' }}
                  className="opacity-20"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="100">
                  <circle r="234" cx="196" cy="23" />
                  <circle r="234" cx="790" cy="491" />
               </Box>
            </svg>
            <Box
               component="svg"
               className="absolute -top-64 -right-64 opacity-20"
               sx={{ color: 'primary.light' }}
               viewBox="0 0 220 192"
               width="220px"
               height="192px"
               fill="none">
               <defs>
                  <pattern
                     id="837c3e70-6c3a-44e6-8854-cc48c737b659"
                     x="0"
                     y="0"
                     width="20"
                     height="20"
                     patternUnits="userSpaceOnUse">
                     <rect
                        x="0"
                        y="0"
                        width="4"
                        height="4"
                        fill="currentColor"
                     />
                  </pattern>
               </defs>
               <rect
                  width="220"
                  height="192"
                  fill="url(#837c3e70-6c3a-44e6-8854-cc48c737b659)"
               />
            </Box>

            <div className="z-10 relative w-full max-w-2xl">
               <div className="text-7xl font-bold leading-none text-awtsyde_green ">
                  <div>Easily Find Someone to</div>
                  <div>Play With</div>
               </div>
               <div className="mt-24 text-lg tracking-tight leading-6 text-gray-400">
                  Awtsyde makes it easy to find players who are available and
                  willing to play.
               </div>
            </div>
         </Box>
      </div>
   );
}

export default SignUpPage;
