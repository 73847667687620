const locale = {
   APPLICATIONS: 'Applications',
   EXAMPLE: 'Example',
   FINDPLAYERS: 'Find Players',
   BOOKCOURT: 'Book Court',
   REFERRALS: 'Referrals',
   HOME: 'Home',
   COMPETE: 'Compete',
   SCHEDULE: 'Schedule',
   DOCUMENTS: 'Documents',
};

export default locale;
