import FuseUtils from '@fuse/utils/FuseUtils';
import axios from 'axios';
import jwtDecode from 'jwt-decode';
import jwtServiceConfig from './jwtServiceConfig';
import { data } from 'autoprefixer';

/* eslint-disable camelcase */

class JwtService extends FuseUtils.EventEmitter {
   init() {
      this.setInterceptors();
      this.handleAuthentication();
   }

   setInterceptors = () => {
      axios.interceptors.response.use(
         (response) => {
            return response;
         },
         (err) => {
            return new Promise((resolve, reject) => {
               if (
                  err.response.status === 401 &&
                  err.config &&
                  !err.config.__isRetryRequest
               ) {
                  // if you ever get an unauthorized response, logout the user
                  this.emit('onAutoLogout', 'Invalid access_token');
                  this.setSession(null);
               }
               throw err;
            });
         }
      );
   };

   handleAuthentication = () => {
      const access_token = this.getAccessToken();

      if (!access_token) {
         this.emit('onNoAccessToken');

         return;
      }

      if (this.isAuthTokenValid(access_token)) {
         this.setSession(access_token);
         this.emit('onAutoLogin', true);
      } else {
         this.setSession(null);
         this.emit('onAutoLogout', 'access_token expired');
      }
   };

   createUser = async ({ password, email }) => {
      try {
         const response = await axios.post(jwtServiceConfig.signUp, {
            password,
            email,
         });
         if (response.data) {
            this.setOtpToken(response.data.data.otpToken);
            this.setEmail(email);
            this.emit('onCreate', response.data.client_message);
         }
      } catch (error) {
         if (error.response.data.data === undefined) {
            this.emit('onError', error.response.data.message);
         } else {
            this.emit('onError', error.response.data.data[0].errors[0]);
         }
      }
   };

   signInWithEmailAndPassword = async (email, password) => {
      try {
         const response = await axios.post(jwtServiceConfig.signIn, {
            email,
            password,
         });
         const userData = response.data.data.user;
         if (userData) {
            this.setSession(response.data.data.token);
            this.emit('onLogin', userData);
            return userData;
         }
      } catch (error) {
         this.emit('onError', error.response.data.message);
      }
   };

   signUpWithPhone = async (phoneNumber) => {
      try {
         const response = await axios.post(jwtServiceConfig.signUpWithPhone, {
            phone: phoneNumber,
         });
         if (response.data) {
            this.setOtpToken(response.data.data.otpToken);
            this.setPhoneNumber(phoneNumber);
            this.emit('onSignupPhone', response.data.client_message);
         }
      } catch (error) {
         console.log(error.response.data.message);
         throw error;
      }
   };

   forgotPassword = async (email) => {
      try {
         const response = await axios.post(jwtServiceConfig.forgotPassword, {
            email,
         });
         const otpToken = response.data.data.otpToken;
         const message = response.data.client_message;
         if (otpToken) {
            // this.setSession(otpToken);
            this.emit('onForgotPassword', message);
            return otpToken;
         }
      } catch (error) {
         console.log(error.response.data.message);
         return error;
      }
   };

   resetPassword = async (password, passwordConfirm) => {
      try {
         const response = await axios.post(jwtServiceConfig.resetPassword, {
            password,
            passwordConfirm,
         });
         const userData = response.data.data.user;
         const message = response.data.client_message;

         if (userData) {
            this.setSession(response.data.data.token);
            this.emit('onResetPassword', response.data);
            return userData;
         }
      } catch (error) {
         console.log(error.response.data.message);
         return error;
      }
   };

   signInWithToken = () => {
      return this.getAccessToken();
   };

   updateUserData = (user) => {
      return axios.post(jwtServiceConfig.updateUser, {
         user,
      });
   };

   setSession = (access_token) => {
      if (access_token) {
         localStorage.setItem('jwt_access_token', access_token);
         axios.defaults.headers.common.Authorization = `Bearer ${access_token}`;
      } else {
         localStorage.removeItem('jwt_access_token');
         delete axios.defaults.headers.common.Authorization;
      }
   };

   setOtpToken = (otpToken) => {
      if (otpToken) {
         localStorage.setItem('otpToken', otpToken);
      } else {
         localStorage.removeItem('otpToken');
      }
   };

   setPhoneNumber = (phoneNumber) => {
      if (phoneNumber) {
         localStorage.setItem('phoneNumber', phoneNumber);
      } else {
         localStorage.removeItem('phoneNumber');
      }
   };

   setEmail = (email) => {
      if (email) {
         localStorage.setItem('email', email);
      } else {
         localStorage.removeItem('email');
      }
   };

   logout = () => {
      this.setSession(null);
      this.emit('onLogout', 'Logged out');
   };

   isAuthTokenValid = (access_token) => {
      if (!access_token) {
         return false;
      }
      const decoded = jwtDecode(access_token);
      const currentTime = Date.now() / 1000;
      if (decoded.exp < currentTime) {
         console.warn('access token expired');
         return false;
      }

      return true;
   };

   getAccessToken = () => {
      return window.localStorage.getItem('jwt_access_token');
   };
}

const instance = new JwtService();

export default instance;
