import { lazy } from 'react';

const StepTwo = lazy(() => import('./StepTwo'));

const StepTwoConfig = {
   settings: {
      layout: {
         config: {
            navbar: {
               display: false,
            },
            toolbar: {
               display: true,
            },
            footer: {
               display: false,
            },
            leftSidePanel: {
               display: false,
            },
            rightSidePanel: {
               display: false,
            },
         },
      },
   },
   routes: [
      {
         path: 'onboarding/step-two',
         element: <StepTwo />,
      },
   ],
};

export default StepTwoConfig;
