import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';
import Paper from '@mui/material/Paper';
import AvatarGroup from '@mui/material/AvatarGroup';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';

function ClubInvitePage() {
   return (
      <div className="flex flex-col flex-auto items-center sm:justify-center min-w-0 md:p-32">
         <Paper className="flex w-full sm:w-auto min-h-full sm:min-h-auto md:w-full md:max-w-6xl rounded-0 sm:rounded-2xl sm:shadow overflow-hidden">
            <div className="w-full sm:w-auto py-32 px-16 sm:p-48 md:p-64 ltr:border-r-1 rtl:border-l-1">
               <div className="w-full max-w-320 sm:w-320 mx-auto sm:mx-0">
                  <img
                     className="w-96"
                     src="assets/images/player/awtsyde-logo.png"
                     alt="logo"
                  />

                  <Typography className="mt-32 text-4xl font-extrabold tracking-tight leading-tight">
                     Club Invitation
                  </Typography>
                  <Typography className="mt-16">
                     You have received an invitation to join <b>Club Name</b>.
                     Joing a Club means you will have access to all club
                     resources and book events within your club. Kindly click on
                     link below to join the club. Don't worry you will be taken
                     through the sign up process if you do not have an account
                  </Typography>

                  <Typography
                     className="mt-32 text-md font-medium"
                     color="text.secondary">
                     <span>Join Club</span>
                     <Link
                        className="ml-4 text-primary-500 hover:underline"
                        to="/sign-in">
                        Click Here !
                     </Link>
                  </Typography>
               </div>
            </div>

            <Box
               className="relative hidden md:flex flex-auto items-center justify-center h-full p-64 lg:px-112 overflow-hidden "
               sx={{
                  backgroundImage: `linear-gradient(to top,#033249, transparent), url('assets/images/player/auth-bg.png')`,
                  backgroundSize: 'cover',
               }}>
               <svg
                  className="absolute inset-0 pointer-events-none"
                  viewBox="0 0 960 540"
                  width="100%"
                  height="100%"
                  preserveAspectRatio="xMidYMax slice"
                  xmlns="http://www.w3.org/2000/svg">
                  <Box
                     component="g"
                     sx={{ color: 'primary.light' }}
                     className="opacity-20"
                     fill="none"
                     stroke="currentColor"
                     strokeWidth="100">
                     <circle r="234" cx="196" cy="23" />
                     <circle r="234" cx="790" cy="491" />
                  </Box>
               </svg>
               <Box
                  component="svg"
                  className="absolute -top-64 -right-64 opacity-20"
                  sx={{ color: 'primary.light' }}
                  viewBox="0 0 220 192"
                  width="220px"
                  height="192px"
                  fill="none">
                  <defs>
                     <pattern
                        id="837c3e70-6c3a-44e6-8854-cc48c737b659"
                        x="0"
                        y="0"
                        width="20"
                        height="20"
                        patternUnits="userSpaceOnUse">
                        <rect
                           x="0"
                           y="0"
                           width="4"
                           height="4"
                           fill="currentColor"
                        />
                     </pattern>
                  </defs>
                  <rect
                     width="220"
                     height="192"
                     fill="url(#837c3e70-6c3a-44e6-8854-cc48c737b659)"
                  />
               </Box>

               <div className="z-10 relative w-full max-w-2xl ">
                  <div className="text-7xl font-bold leading-none text-awtsyde_green">
                     <div>Welcome to</div>
                     <div>Awtsyde</div>
                  </div>
                  <div className="mt-24 text-lg tracking-tight leading-6 text-gray-400">
                     Awtsyde makes it easy to find players who are available and
                     willing to play.
                  </div>
                  <div className="flex items-center mt-32">
                     <AvatarGroup
                        sx={{
                           '& .MuiAvatar-root': {
                              borderColor: 'primary.main',
                           },
                        }}>
                        <Avatar src="assets/images/avatars/female-18.jpg" />
                        <Avatar src="assets/images/avatars/female-11.jpg" />
                        <Avatar src="assets/images/avatars/male-09.jpg" />
                        <Avatar src="assets/images/avatars/male-16.jpg" />
                     </AvatarGroup>

                     <div className="ml-16 font-medium tracking-tight text-gray-400">
                        More than 17k people joined us, it's your turn
                     </div>
                  </div>
               </div>
            </Box>
         </Paper>
      </div>
   );
}

export default ClubInvitePage;
