import { lazy } from 'react';

const StepOne = lazy(() => import('./StepOne'));

const StepOneConfig = {
   settings: {
      layout: {
         config: {
            navbar: {
               display: false,
            },
            toolbar: {
               display: true,
            },
            footer: {
               display: false,
            },
            leftSidePanel: {
               display: false,
            },
            rightSidePanel: {
               display: false,
            },
         },
      },
   },
   routes: [
      {
         path: '/onboarding/step-one',
         element: <StepOne />,
      },
   ],
};

export default StepOneConfig;
