import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import history from '@history';
import _ from '@lodash';
import { setInitialSettings } from 'app/store/fuse/settingsSlice';
import { showMessage } from 'app/store/fuse/messageSlice';
import settingsConfig from 'app/configs/settingsConfig';
import jwtService from '../auth/services/jwtService';
import axios from 'axios';
import { baseUrl } from 'app/configs/apiConfig';

export const setUser = createAsyncThunk(
   'user/setUser',
   async (user, { dispatch, getState }) => {
      if (user.data.isOnboardingComplete === false) {
         settingsConfig.loginRedirectUrl = '/onboarding/step-one';
      }else{
         settingsConfig.loginRedirectUrl = '/apps/profile'; 
      }
      return user;
   }
);



export const confirmAccount = () => async (dispatch, getState) => {
   history.push({
      pathname: '/confirm-account',
   });
};

export const verifyOtp = createAsyncThunk(
   'user/verifyOtp',
   async (user, { dispatch, getState }) => {
      const response = await axios.post(
         `${baseUrl}/auth/signup/otp-verify`,
         user
      );
      const data = await response.data;
       history.push({
          pathname: '/sign-in',
       });
      localStorage.removeItem('otpToken');      
      localStorage.removeItem('phoneNumber');   
      return data;
   }
);

// export const setUser = createAsyncThunk(
//   "user/setUser",
//   async (user, { rejectWithValue, fulfillWithValue, dispatch, getState }) => {
//     /*
//     You can redirect the logged-in user to a specific route depending on his role
//     */
//     // if (user.loginRedirectUrl) {
//     //   settingsConfig.loginRedirectUrl = user.loginRedirectUrl; // for example 'apps/academy'
//     // }

//     try {
//       const res = await ProfileService.getProfile();
//       const userDta = res.data;
//       const newUser = {
//         ...user,
//         data: {
//           ...user.data,
//           ...userDta,
//           name: `${userDta.firstName} ${userDta.lastName}`,
//         },
//       };
//       dispatch(showMessage({ message: res.client_message }));
//       return fulfillWithValue(newUser);
//     } catch (error) {
//       console.log(error);
//       dispatch(showMessage({ message: error.response.data.message.clientMsg }));
//       return rejectWithValue(error);
//     }
//   }
// );

export const updateUserSettings = createAsyncThunk(
   'user/updateSettings',
   async (settings, { dispatch, getState }) => {
      const { user } = getState();
      const newUser = _.merge({}, user, { data: { settings } });
      'user/updateSettings',
         async (settings, { dispatch, getState }) => {
            const { user } = getState();
            const newUser = _.merge({}, user, { data: { settings } });

            dispatch(updateUserData(newUser));
            dispatch(updateUserData(newUser));

            return newUser;
         };
      return newUser;
   }
);

export const updateUserShortcuts = createAsyncThunk(
   'user/updateShortucts',
   async (shortcuts, { dispatch, getState }) => {
      const { user } = getState();
      const newUser = {
         ...user,
         data: {
            ...user.data,
            shortcuts,
         },
      };
      'user/updateShortucts',
         async (shortcuts, { dispatch, getState }) => {
            const { user } = getState();
            const newUser = {
               ...user,
               data: {
                  ...user.data,
                  shortcuts,
               },
            };

            dispatch(updateUserData(newUser));
            dispatch(updateUserData(newUser));

            return newUser;
         };
      return newUser;
   }
);

export const logoutUser = () => async (dispatch, getState) => {
   const { user } = getState();

   if (!user.role || user.role.length === 0) {
      // is guest
      return null;
   }
   if (!user.role || user.role.length === 0) {
      // is guest
      return null;
   }

   history.push({
      pathname: '/',
   });
   history.push({
      pathname: '/',
   });

   dispatch(setInitialSettings());
   dispatch(setInitialSettings());

   return dispatch(userLoggedOut());
   return dispatch(userLoggedOut());
};

export const updateUserData = (user) => async (dispatch, getState) => {
   if (!user.role || user.role.length === 0) {
      // is guest
      return;
   }
   if (!user.role || user.role.length === 0) {
      // is guest
      return;
   }

   jwtService
      .updateUserData(user)
      .then(() => {
         dispatch(showMessage({ message: 'User data saved with api' }));
      })
      .catch((error) => {
         dispatch(showMessage({ message: error.message }));
      });
   jwtService
      .updateUserData(user)
      .then(() => {
         dispatch(showMessage({ message: 'User data saved with api' }));
      })
      .catch((error) => {
         dispatch(showMessage({ message: error.message }));
      });
};

const initialState = {
   role: [], // guest
   data: {
      displayName: 'John Doe',
      photoURL: 'assets/images/avatars/brian-hughes.jpg',
      email: 'johndoe@withinpixels.com',
      shortcuts: [
         'apps.calendar',
         'apps.mailbox',
         'apps.contacts',
         'apps.tasks',
      ],
   },
   role: [], // guest
   data: {
      displayName: 'John Doe',
      photoURL: 'assets/images/avatars/brian-hughes.jpg',
      email: 'johndoe@withinpixels.com',
      shortcuts: [
         'apps.calendar',
         'apps.mailbox',
         'apps.contacts',
         'apps.tasks',
      ],
   },
};

const userSlice = createSlice({
   name: 'user',
   initialState,
   reducers: {
      userLoggedOut: (state, action) => initialState,
   },
   extraReducers: {
      [updateUserSettings.fulfilled]: (state, action) => action.payload,
      [updateUserShortcuts.fulfilled]: (state, action) => action.payload,
      [setUser.fulfilled]: (state, action) => action.payload,
   },
   name: 'user',
   initialState,
   reducers: {
      userLoggedOut: (state, action) => initialState,
   },
   extraReducers: {
      [updateUserSettings.fulfilled]: (state, action) => action.payload,
      [updateUserShortcuts.fulfilled]: (state, action) => action.payload,
      [setUser.fulfilled]: (state, action) => action.payload,
   },
});

export const { userLoggedOut } = userSlice.actions;

export const selectUser = ({ user }) => user;

export const selectUserShortcuts = ({ user }) => user.data.shortcuts;

export default userSlice.reducer;
