import ClubInvitePage from './ClubInvitePage';


const ClubInviteConfig = {
   settings: {
      layout: {
         config: {
            navbar: {
               display: false,
            },
            toolbar: {
               display: false,
            },
            footer: {
               display: false,
            },
            leftSidePanel: {
               display: false,
            },
            rightSidePanel: {
               display: false,
            },
         },
      },
   },
   auth: null,
   routes: [
      {
         path: 'club-invite',
         element: <ClubInvitePage />,
      },
   ],
};

export default ClubInviteConfig;
