import { useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import * as yup from 'yup';
import _ from '@lodash';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { IconButton } from '@mui/material';
import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import jwtService from '../../auth/services/jwtService';

/**
 * Form Validation Schema
 */
const schema = yup.object().shape({
   password: yup
      .string()
      .required('Please enter your password.')
      .min(8, 'Password is too short - should be 8 chars minimum.'),
   passwordConfirm: yup
      .string()
      .oneOf([yup.ref('password'), null], 'Passwords must match'),
});

const defaultValues = {
   password: '',
   passwordConfirm: '',
};

function ResetPasswordPage() {
   // State to track whether to show the password
   const [showPassword, setShowPassword] = useState(false);
   const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);

   const { control, formState, handleSubmit, reset } = useForm({
      mode: 'onChange',
      defaultValues,
      resolver: yupResolver(schema),
   });

   const { isValid, dirtyFields, errors, setError } = formState;

   function onSubmit({ password, passwordConfirm }) {
      jwtService
         .resetPassword({
            password,
            passwordConfirm,
         })
         .then((user) => {
            // No need to do anything, registered user data will be set at app/auth/AuthContext
         })
         .catch((_errors) => {
            _errors.forEach((error) => {
               setError(error.type, {
                  type: 'manual',
                  message: error.message,
               });
            });
         });
   }

   return (
      <div className="flex flex-col sm:flex-row items-center md:items-start sm:justify-center md:justify-start flex-1 min-w-0">
         <Paper className="h-full sm:h-auto md:flex md:items-center md:justify-end w-full sm:w-auto md:h-full md:w-1/2 py-8 px-16 sm:p-48 md:p-64 sm:rounded-2xl md:rounded-none sm:shadow md:shadow-none ltr:border-r-1 rtl:border-l-1">
            <div className="w-full max-w-360 sm:w-360 mx-auto sm:mx-0">
               <img
                  className="w-96"
                  src="assets/images/player/awtsyde-logo.png"
                  alt="logo"
               />

               <Typography className="mt-32 text-4xl font-extrabold tracking-tight leading-tight">
                  Reset Your Password
               </Typography>
               <div className="flex items-baseline mt-2 font-medium">
                  <Typography>Enter your new password</Typography>
               </div>

               <form
                  name="registerForm"
                  noValidate
                  className="flex flex-col justify-center w-full mt-32"
                  onSubmit={handleSubmit(onSubmit)}>
                  <Controller
                     name="password"
                     control={control}
                     render={({ field }) => (
                        <TextField
                           {...field}
                           className="mb-24"
                           label="Password"
                           type={showPassword ? 'text' : 'password'} // Toggle password visibility
                           error={!!errors.password}
                           helperText={errors?.password?.message}
                           variant="outlined"
                           required
                           fullWidth
                           InputProps={{
                              endAdornment:
                                 // <Button
                                 //   onClick={() => setShowPassword(!showPassword)}
                                 //   variant="contained"
                                 //   size="small"
                                 //   style={{
                                 //     border:"none",
                                 //     outline:"none"
                                 //    }}
                                 // >
                                 //   {/*  replace with icons */}
                                 // </Button>
                                 showPassword ? (
                                    <IconButton
                                       aria-label="eye-off"
                                       onClick={() =>
                                          setShowPassword(!showPassword)
                                       }>
                                       <FuseSvgIcon
                                          className="text-48"
                                          size={24}
                                          color="action">
                                          heroicons-outline:eye-off
                                       </FuseSvgIcon>
                                    </IconButton>
                                 ) : (
                                    <IconButton
                                       aria-label="eye"
                                       onClick={() =>
                                          setShowPassword(!showPassword)
                                       }>
                                       <FuseSvgIcon
                                          className="text-48"
                                          size={24}
                                          color="action">
                                          heroicons-outline:eye
                                       </FuseSvgIcon>
                                    </IconButton>
                                 ),
                           }}
                        />
                     )}
                  />
                  <Controller
                     name="passwordConfirm"
                     control={control}
                     render={({ field }) => (
                        <TextField
                           {...field}
                           className="mb-24"
                           label="Confirm Password"
                           type={showPassword ? 'text' : 'password'} // Toggle password visibility
                           error={!!errors.passwordConfirm}
                           helperText={errors?.passwordConfirm?.message}
                           variant="outlined"
                           required
                           fullWidth
                           InputProps={{
                              endAdornment: showPasswordConfirm ? (
                                 <IconButton
                                    aria-label="eye-off"
                                    onClick={() =>
                                       setShowPasswordConfirm(
                                          !showPasswordConfirm
                                       )
                                    }>
                                    <FuseSvgIcon
                                       className="text-48"
                                       size={24}
                                       color="action">
                                       heroicons-outline:eye-off
                                    </FuseSvgIcon>
                                 </IconButton>
                              ) : (
                                 <IconButton
                                    aria-label="eye"
                                    onClick={() =>
                                       setShowPasswordConfirm(
                                          !showPasswordConfirm
                                       )
                                    }>
                                    <FuseSvgIcon
                                       className="text-48"
                                       size={24}
                                       color="action">
                                       heroicons-outline:eye
                                    </FuseSvgIcon>
                                 </IconButton>
                              ),
                           }}
                        />
                     )}
                  />

                  <Button
                     variant="contained"
                     className="w-full mt-24 bg-awtsyde_green"
                     aria-label="Register"
                     disabled={_.isEmpty(dirtyFields) || !isValid}
                     type="submit"
                     size="large">
                     Reset password
                  </Button>
               </form>
            </div>
         </Paper>

         <Box
            className="relative hidden md:flex flex-auto items-center justify-center h-full p-64 lg:px-112 overflow-hidden"
            sx={{
               backgroundImage: `linear-gradient(to top,#033249, transparent), url('assets/images/player/auth-bg.png')`,
               backgroundSize: 'cover',
            }}>
            <svg
               className="absolute inset-0 pointer-events-none"
               viewBox="0 0 960 540"
               width="100%"
               height="100%"
               preserveAspectRatio="xMidYMax slice"
               xmlns="http://www.w3.org/2000/svg">
               <Box
                  component="g"
                  sx={{ color: 'primary.light' }}
                  className="opacity-20"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="100">
                  <circle r="234" cx="196" cy="23" />
                  <circle r="234" cx="790" cy="491" />
               </Box>
            </svg>
            <Box
               component="svg"
               className="absolute -top-64 -right-64 opacity-20"
               sx={{ color: 'primary.light' }}
               viewBox="0 0 220 192"
               width="220px"
               height="192px"
               fill="none">
               <defs>
                  <pattern
                     id="837c3e70-6c3a-44e6-8854-cc48c737b659"
                     x="0"
                     y="0"
                     width="20"
                     height="20"
                     patternUnits="userSpaceOnUse">
                     <rect
                        x="0"
                        y="0"
                        width="4"
                        height="4"
                        fill="currentColor"
                     />
                  </pattern>
               </defs>
               <rect
                  width="220"
                  height="192"
                  fill="url(#837c3e70-6c3a-44e6-8854-cc48c737b659)"
               />
            </Box>

            <div className="z-10 relative w-full max-w-2xl">
               <div className="text-7xl font-bold leading-none text-awtsyde_green ">
                  <div>Easily Find Someone to</div>
                  <div>Play With</div>
               </div>
               <div className="mt-24 text-lg tracking-tight leading-6 text-gray-400">
                  Awtsyde makes it easy to find players who are available and
                  willing to play.
               </div>
            </div>
         </Box>
      </div>
   );
}

export default ResetPasswordPage;
