import { lazy } from 'react';

const ProfileComplete = lazy(() => import('./ProfileComplete'));

const ProfileCompleteConfig = {
   settings: {
      layout: {
         config: {
            navbar: {
               display: false,
            },
            toolbar: {
               display: true,
            },
            footer: {
               display: false,
            },
            leftSidePanel: {
               display: false,
            },
            rightSidePanel: {
               display: false,
            },
         },
      },
   },
   routes: [
      {
         path: 'onboarding/profile-complete',
         element: <ProfileComplete />,
      },
   ],
};

export default ProfileCompleteConfig;
