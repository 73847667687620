import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { baseUrl } from 'app/configs/apiConfig';

export const getUserProfile = createAsyncThunk(
   'profile/getProfile',
   async (_, { dispatch, getState }) => {
      try {
         const response = await axios.get(`${baseUrl}/profile`);
         const userData = await response.data;
         return userData;
      } catch (error) {
         console.error('Error fetching user profile:', error);
         throw error;
      }
   }
);

const profileSlice = createSlice({
   name: 'profile',
   initialState: {
      profile: null,
      status: 'idle',
      error: null,
   },
   reducers: {},
   extraReducers: (builder) => {
      builder
         .addCase(getUserProfile.pending, (state) => {
            state.status = 'loading';
         })
         .addCase(getUserProfile.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.profile = action.payload;
         })
         .addCase(getUserProfile.rejected, (state, action) => {
            state.status = 'failed';
            state.error = action.error.message;
         });
   },
});

export default profileSlice.reducer;
