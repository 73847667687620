import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Grid from '@mui/system/Unstable_Grid/Grid';
import { Link } from 'react-router-dom';
import * as yup from 'yup';
import _ from '@lodash';
import AvatarGroup from '@mui/material/AvatarGroup';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import FormHelperText from '@mui/material/FormHelperText';
import jwtService from '../../auth/services/jwtService';
import { useDispatch } from 'react-redux';
import { verifyOtp } from 'app/store/userSlice';

/**
 * Form Validation Schema
 */
const schema = yup.object().shape({
   otp1: yup.string().required('You must a number'),
   otp2: yup.string().required('You must a number'),
   otp3: yup.string().required('You must a number'),
   otp4: yup.string().required('You must a number'),
});

const defaultValues = {
   otp1: '',
   otp2: '',
   otp3: '',
   otp4: '',
};

function ForgotPasswordPage() {
   const { control, formState, handleSubmit, reset } = useForm({
      mode: 'onChange',
      defaultValues,
      resolver: yupResolver(schema),
   });

   const { isValid, dirtyFields, errors, setError } = formState;

   const dispatch = useDispatch();

   function onSubmit({otp1, otp2, otp3, otp4}) {
      const phone = localStorage.getItem('phoneNumber');
      const email = localStorage.getItem('email');
      const otpToken = localStorage.getItem('otpToken');
      try {
         dispatch(verifyOtp({ otpCode: otp1 + otp2 + otp3 + otp4, phone, email, otpToken }));
      } catch (error) {
         console.log('Error', error);
      }
   }

   return (
      <div className="flex flex-col sm:flex-row items-center md:items-start sm:justify-center md:justify-start flex-1 min-w-0">
         <Paper className="h-full sm:h-auto md:flex md:items-center md:justify-end w-full sm:w-auto md:h-full md:w-1/2 py-8 px-16 sm:p-48 md:p-64 sm:rounded-2xl md:rounded-none sm:shadow md:shadow-none ltr:border-r-1 rtl:border-l-1">
            <div className="w-full max-w-320 sm:w-320 mx-auto sm:mx-0">
               <img
                  className="w-96"
                  src="assets/images/player/awtsyde-logo.png"
                  alt="logo"
               />

               <Typography className="mt-32 text-4xl font-extrabold tracking-tight leading-tight">
                  Confirm Account
               </Typography>
               <div className="flex items-baseline mt-2 font-medium">
                  <Typography>
                     Enter the 4 - digit OTP sent to your email / phone number
                  </Typography>
               </div>

               <form
                  name="registerForm"
                  noValidate
                  className="flex flex-col justify-center w-full mt-32"
                  onSubmit={handleSubmit(onSubmit)}>
                  <Grid
                     container
                     direction="row"
                     columns={{ xs: 4, sm: 4, md: 4 }}
                     justifyContent="space-between"
                     alignItems="center">
                     <Controller
                        name="otp1"
                        control={control}
                        render={({ field }) => (
                           <TextField
                              {...field}
                              className="mb-24 w-60"
                              label=""
                              type="text"
                              variant="outlined"
                              required
                              fullWidth
                           />
                        )}
                     />

                     <Controller
                        name="otp2"
                        control={control}
                        render={({ field }) => (
                           <TextField
                              {...field}
                              className="mb-24 w-60"
                              label=""
                              type="text"
                              variant="outlined"
                              required
                              fullWidth
                           />
                        )}
                     />

                     <Controller
                        name="otp3"
                        control={control}
                        render={({ field }) => (
                           <TextField
                              {...field}
                              className="mb-24 w-60"
                              label=""
                              type="text"
                              variant="outlined"
                              required
                              fullWidth
                           />
                        )}
                     />

                     <Controller
                        name="otp4"
                        control={control}
                        render={({ field }) => (
                           <TextField
                              {...field}
                              className="mb-24 w-60"
                              label=""
                              type="text"
                              variant="outlined"
                              required
                              fullWidth
                           />
                        )}
                     />
                  </Grid>
                  <Button
                     variant="contained"
                     className="w-full mt-24 bg-awtsyde_green"
                     aria-label="Register"
                     disabled={_.isEmpty(dirtyFields) || !isValid}
                     type="submit"
                     size="large">
                     Continue
                  </Button>
               </form>

               <div className="flex items-baseline pt-4 font-medium">
                  <Typography>Did not receive the code?</Typography>
                  <Link className="ml-4" to="#">
                     Resend Code
                  </Link>
               </div>
            </div>
         </Paper>

         <Box
            className="relative hidden md:flex flex-auto items-center justify-center h-full p-64 lg:px-112 overflow-hidden"
            sx={{
               backgroundImage: `linear-gradient(to top,#033249, transparent), url('assets/images/player/auth-bg.png')`,
               backgroundSize: 'cover',
            }}>
            <svg
               className="absolute inset-0 pointer-events-none"
               viewBox="0 0 960 540"
               width="100%"
               height="100%"
               preserveAspectRatio="xMidYMax slice"
               xmlns="http://www.w3.org/2000/svg">
               <Box
                  component="g"
                  sx={{ color: 'primary.light' }}
                  className="opacity-20"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="100">
                  <circle r="234" cx="196" cy="23" />
                  <circle r="234" cx="790" cy="491" />
               </Box>
            </svg>
            <Box
               component="svg"
               className="absolute -top-64 -right-64 opacity-20"
               sx={{ color: 'primary.light' }}
               viewBox="0 0 220 192"
               width="220px"
               height="192px"
               fill="none">
               <defs>
                  <pattern
                     id="837c3e70-6c3a-44e6-8854-cc48c737b659"
                     x="0"
                     y="0"
                     width="20"
                     height="20"
                     patternUnits="userSpaceOnUse">
                     <rect
                        x="0"
                        y="0"
                        width="4"
                        height="4"
                        fill="currentColor"
                     />
                  </pattern>
               </defs>
               <rect
                  width="220"
                  height="192"
                  fill="url(#837c3e70-6c3a-44e6-8854-cc48c737b659)"
               />
            </Box>

            <div className="z-10 relative w-full max-w-2xl">
               <div className="text-7xl font-bold leading-none text-awtsyde_green ">
                  <div>Easily Find Someone to</div>
                  <div>Play With</div>
               </div>
               <div className="mt-24 text-lg tracking-tight leading-6 text-gray-400">
                  Awtsyde makes it easy to find players who are available and
                  willing to play.
               </div>
            </div>
         </Box>
      </div>
   );
}

export default ForgotPasswordPage;
