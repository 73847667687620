import i18next from 'i18next';
import ar from './navigation-i18n/ar';
import en from './navigation-i18n/en';
import tr from './navigation-i18n/tr';

i18next.addResourceBundle('en', 'navigation', en);
i18next.addResourceBundle('tr', 'navigation', tr);
i18next.addResourceBundle('ar', 'navigation', ar);

const navigationConfig = [
   {
      id: 'home-component',
      title: 'Home',
      translate: 'HOME',
      type: 'item',
      icon: 'heroicons-outline:home',
      url: '/apps/profile',
   },
   {
      id: 'compete-component',
      title: 'Compete',
      translate: 'COMPETE',
      type: 'item',
      icon: 'heroicons-outline:scale',
      url: '/apps/compete',
   },
   {
      id: 'find-players-component',
      title: 'Find Players',
      translate: 'FINDPLAYERS',
      type: 'item',
      icon: 'heroicons-outline:users',
      url: '/apps/players',
   },
   {
      id: 'book-court-component',
      title: 'Book Court',
      translate: 'BOOKCOURT',
      type: 'item',
      icon: 'heroicons-outline:clipboard-check',
      url: '/apps/courts',
   },
   {
      id: 'referrals-component',
      title: 'Referrals',
      translate: 'REFERRALS',
      type: 'item',
      icon: 'heroicons-outline:gift',
      url: '/apps/referrals',
   },
   {
      id: 'schedule-component',
      title: 'Schedule',
      translate: 'SCHEDULE',
      type: 'item',
      icon: 'heroicons-outline:calendar',
      url: '/apps/schedule',
   },
   {
      id: 'documents-component',
      title: 'Documents',
      translate: 'DOCUMENTS',
      type: 'item',
      icon: 'heroicons-outline:document-text',
      url: '/apps/documents',
   },
];

export default navigationConfig;
