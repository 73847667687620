import { lazy } from 'react';

const StepFive = lazy(() => import('./StepFive'));

const StepFiveConfig = {
   settings: {
      layout: {
         config: {
            navbar: {
               display: false,
            },
            toolbar: {
               display: true,
            },
            footer: {
               display: false,
            },
            leftSidePanel: {
               display: false,
            },
            rightSidePanel: {
               display: false,
            },
         },
      },
   },
   routes: [
      {
         path: 'onboarding/step-five',
         element: <StepFive />,
      },
   ],
};

export default StepFiveConfig;
