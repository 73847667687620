/**
 * Authorization Roles
 */
const authRoles = {
   player: ['player'],
   staff: ['admin', 'staff'],
   user: ['admin', 'staff', 'user'],
   onlyGuest: [],
};

export default authRoles;
