import { baseUrl } from '../constants';

const jwtServiceConfig = {
   signIn: `${baseUrl}/auth/signin/email`,
   signUp: `${baseUrl}/auth/signup/email`,
   signUpWithPhone: `${baseUrl}/auth/signup/phone`,
   accessToken: 'api/auth/access-token',
   updateUser: 'api/auth/user/update',
   resetPassword: `${baseUrl}/auth/reset-password`,
   forgotPassword: `${baseUrl}/auth/initiate-forget-password`,
};

export default jwtServiceConfig;
