import ConfirmAccountPage from './ConfirmAccountPage';
import authRoles from '../../auth/authRoles';

const ConfirmAccountConfig = {
   settings: {
      layout: {
         config: {
            navbar: {
               display: false,
            },
            toolbar: {
               display: false,
            },
            footer: {
               display: false,
            },
            leftSidePanel: {
               display: false,
            },
            rightSidePanel: {
               display: false,
            },
         },
      },
   },
   auth: authRoles.onlyGuest,
   routes: [
      {
         path: '/confirm-account',
         element: <ConfirmAccountPage />,
      },
   ],
};

export default ConfirmAccountConfig;
