import FuseUtils from '@fuse/utils';
import FuseLoading from '@fuse/core/FuseLoading';
import { Navigate } from 'react-router-dom';
import settingsConfig from 'app/configs/settingsConfig';
import SignInConfig from '../main/sign-in/SignInConfig';
import SignUpConfig from '../main/sign-up/SignUpConfig';
import SignUpWithPhoneConfig from '../main/sign-up-phone/SignUpWithPhoneConfig';
import SignOutConfig from '../main/sign-out/SignOutConfig';
import ResetPasswordConfig from '../main/reset-password/ResetPasswordConfig';
import ForgotPasswordConfig from '../main/forgot-password/ForgotPasswordConfig';
import ConfirmAccountConfig from '../main/confirm-account/ConfirmAccountConfig';
import Error404Page from '../main/404/Error404Page';
import ExampleConfig from '../main/example/ExampleConfig';
import HomeConfig from '../main/profile/homeAppConfig';
import CourtConfig from "../main/court/CourtConfig";
import StepOneConfig from '../main/onboarding/step-one/StepOneConfig';
import StepTwoConfig from '../main/onboarding/step-two/StepTwoConfig';
import StepThreeConfig from '../main/onboarding/step-three/StepThreeConfig';
import StepFourConfig from '../main/onboarding/step-four/StepFourConfig';
import StepFiveConfig from '../main/onboarding/step-five/StepFiveConfig';
import StepSixConfig from '../main/onboarding/step-six/StepSixConfig';
import ProfileCompleteConfig from '../main/onboarding/profile-complete/ProfileCompleteConfig';
import PermissionsConfig from '../main/onboarding/permissions/PermissionsConfig';
import PlayersConfig from "../main/players/PlayersConfig";
import ClubInviteConfig from '../main/club-invite/ClubInviteConfig';

const routeConfigs = [
   ExampleConfig,
   SignOutConfig,
   SignInConfig,
   SignUpConfig,
   ClubInviteConfig,
   SignUpWithPhoneConfig,
   ResetPasswordConfig,
   ForgotPasswordConfig,
   ConfirmAccountConfig,
   HomeConfig,
   PlayersConfig,
   CourtConfig,
   StepOneConfig,
   StepTwoConfig,
   StepThreeConfig,
   StepFourConfig,
   StepFiveConfig,
   StepSixConfig,
   ProfileCompleteConfig,
   PermissionsConfig,
];

const routes = [
  ...FuseUtils.generateRoutesFromConfigs(
    routeConfigs,
    settingsConfig.defaultAuth
  ),
  {
    path: "/",
    element: <Navigate to="/apps/profile" />,
    auth: settingsConfig.defaultAuth,
  },
  {
    path: "home",
    element: <Navigate to="/apps/profile" />,
  },
  {
    path: "loading",
    element: <FuseLoading />,
  },
  {
    path: "404",
    element: <Error404Page />,
  },
  {
    path: "*",
    element: <Navigate to="404" />,
  },
];

export default routes;
