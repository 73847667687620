import { lazy } from 'react';

const Permissions = lazy(() => import('./Permissions'));

const Permissionsonfig = {
   settings: {
      layout: {
         config: {
            navbar: {
               display: false,
            },
            toolbar: {
               display: true,
            },
            footer: {
               display: false,
            },
            leftSidePanel: {
               display: false,
            },
            rightSidePanel: {
               display: false,
            },
         },
      },
   },
   routes: [
      {
         path: 'onboarding/permissions',
         element: <Permissions />,
      },
   ],
};

export default Permissionsonfig;
