import { styled } from '@mui/material/styles';

const Root = styled('div')(({ theme }) => ({
   '& > .logo-icon': {
      transition: theme.transitions.create(['width', 'height'], {
         duration: theme.transitions.duration.shortest,
         easing: theme.transitions.easing.easeInOut,
      }),
   },
   '& > .badge': {
      transition: theme.transitions.create('opacity', {
         duration: theme.transitions.duration.shortest,
         easing: theme.transitions.easing.easeInOut,
      }),
   },
}));

function Logo() {
   return (
      <Root className="flex items-center">
         <img
            className="logo-icon w-96"
            src="assets/images/player/awtsyde-logo-white.svg"
            alt="logo"
         />
      </Root>
   );
}

export default Logo;
